import slugify from "react-slugify";
import c from "classnames";

import Styles from "./video.module.scss";
import { useVideo } from "./useVideo";

type Props = {
	src: string;
	id: string;
	poster?: string;
	autoplay?: boolean;
	muted?: boolean;
	mask?: string;
	backToPoster?: boolean;
	loop?: boolean;
	hasControls?: boolean;
	hidePlayBtn?: boolean;
	autoplayWhenInView?: boolean;
};

export const Video = ({
	src,
	id,
	poster,
	autoplay = true,
	muted = false,
	mask,
	backToPoster = false,
	loop = true,
	hasControls = false,
	hidePlayBtn = false,
	autoplayWhenInView = false,
}: Props) => {
	const { videoPlaying, playVideo, setVideoPlaying, videoRef, onClickVideo } =
		useVideo({
			backToPoster,
			id,
			src,
			hasControls,
			autoplay,
			autoplayWhenInView,
		});

	return (
		<>
			<div
				className={c(Styles.playButton, {
					[Styles.isPlaying]: videoPlaying || hidePlayBtn,
				})}
				onClick={playVideo}
			></div>
			<video
				id={"video-" + slugify(id)}
				poster={poster}
				loop={loop}
				muted={muted}
				autoPlay={autoplay && !autoplayWhenInView}
				playsInline
				controls={hasControls}
				onPlay={() => {
					!videoPlaying && setVideoPlaying(true);
				}}
				onPause={() => {
					videoPlaying && setVideoPlaying(false);
				}}
				ref={videoRef}
				onClick={onClickVideo}
				style={{
					mask: mask && "url(/images/fancy-square.svg#fancy-square)",
					maskRepeat: "no-repeat",
					WebkitMaskBoxImage:
						mask && "url(/images/fancy-square.svg#fancy-square)",
				}}
			>
				<source src={src} type="video/mp4" />
			</video>
		</>
	);
};
