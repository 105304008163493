import c from "classnames";

import { AccordionProps } from "@/types/layout";

import { useAccordion } from "./useAccordion";
import Styles from "./accordion.module.scss";

import { Icon } from "../Icon";

export const Accordion = ({
	heading,
	id,
	type = "text",
	children,
	disabled = false,
	alternate = false,
	isNegative = false,
	inModal = false,
	noMargin = false,
	leftAlign = false,
	noHeadingPad = false,
	noPad = false,
	iconPosition = "end",
	wrapperClassName = "",
	...rest
}: AccordionProps) => {
	const { toggleAccordion, accordionContent, open } = useAccordion({
		id,
		...rest,
	});

	return (
		<>
			<div
				className={c(Styles.wrapper, {
					[Styles.isOpen]: open,
					[Styles.noMargin]: noMargin,
					[Styles.leftAlign]: leftAlign,
					[Styles.noHeadingPad]: noHeadingPad,
					[Styles.noPad]: noPad,
					[wrapperClassName]: !!wrapperClassName,
					[Styles.disabled]: disabled,
				})}
			>
				<div
					className={c(Styles.accordionHeading, {
						[Styles.accordionHeadingNegative]: isNegative,
						[Styles.rightIcon]: iconPosition === "end",
						[Styles.leftIcon]: iconPosition === "start",
					})}
					onClick={disabled ? () => {} : toggleAccordion}
				>
					{type === "text" ? (
						heading
					) : (
						<span dangerouslySetInnerHTML={{ __html: heading }} />
					)}{" "}
					<Icon
						className={Styles.icon}
						height="36px"
						width="36px"
						icon={open ? "ChevronUp" : "ChevronDown"}
					/>
				</div>
				<div className={Styles.accordionContent} ref={accordionContent}>
					{children}
				</div>
			</div>

			{alternate && !open && (
				<div
					className="accordion-alternate"
					style={{ cursor: "pointer" }}
					onClick={toggleAccordion}
				>
					{alternate}
				</div>
			)}
		</>
	);
};
