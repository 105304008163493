import { useEffect, useState } from "react";

export const usePersistState = ({
	defaultValue,
	key,
}: {
	defaultValue: any;
	key: string;
}) => {
	const [value, setValue] = useState(() => {
		try {
			const persistVal =
				typeof window !== "undefined"
					? window.sessionStorage.getItem(key) || ""
					: "";

			try {
				return JSON.parse(persistVal);
			} catch (_e) {
				return typeof persistVal !== "undefined" ? persistVal : defaultValue;
			}
		} catch (e) {
			console.log(e);

			return defaultValue;
		}
	});

	useEffect(() => {
		try {
			window.sessionStorage.setItem(key, JSON.stringify(value));
		} catch (_e) {
			console.log("Can't access window");
		}
	}, [key, value]);

	return {
		value,
		setValue,
	};
};
