import {
	MouseEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";

import { track } from "@/helpers/analytics";

type Props = {
	src: string;
	id: string;
	backToPoster?: boolean;
	hasControls?: boolean;
	autoplay?: boolean;
	autoplayWhenInView?: boolean;
};

export const useVideo = ({
	backToPoster,
	id,
	src,
	hasControls,
	autoplay,
	autoplayWhenInView,
}: Props) => {
	const [videoPlaying, setVideoPlaying] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);

	const playVideo = useCallback(() => {
		if (!videoRef.current) {
			return;
		}

		if (videoRef.current.paused) {
			videoRef.current.play();
			track("Play Video", {
				video: id,
			});
			return;
		}

		videoRef.current.pause();
		if (backToPoster) {
			setVideoPlaying(false);
			videoRef.current.load();
		}
	}, [backToPoster, id]);

	useEffect(() => {
		videoRef.current?.load();
	}, [src]);

	const onClickVideo: MouseEventHandler<HTMLVideoElement> = useCallback(
		(_event) => {
			try {
				if (!videoRef.current) {
					return;
				}

				if (videoPlaying) {
					setVideoPlaying(false);

					if (!hasControls) {
						videoRef.current.pause();
					}
					return;
				}

				if (!videoPlaying) {
					setVideoPlaying(true);

					if (!hasControls) {
						videoRef.current.play();
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		[hasControls, videoPlaying]
	);

	useEffect(() => {
		if (!autoplay || !autoplayWhenInView || !videoRef.current) {
			return;
		}

		const onScroll = () => {
			if (!videoRef.current) {
				return;
			}

			const topOfVideo =
				videoRef.current?.getBoundingClientRect().top + window.scrollY;

			const scrollBottom =
				window.scrollY + document.documentElement.clientHeight;

			if (scrollBottom > topOfVideo) {
				videoRef.current?.play();
				setVideoPlaying(true);
			}
		};

		onScroll();

		window.addEventListener("scroll", onScroll);

		if (videoPlaying) {
			window.removeEventListener("scroll", onScroll);
		}

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, [autoplay, autoplayWhenInView, videoPlaying]);

	return { videoPlaying, playVideo, setVideoPlaying, videoRef, onClickVideo };
};
