import { MouseEventHandler, useCallback, useRef, useState } from "react";

import { slideToggle } from "@/helpers/helperFunctions";
import { AccordionProps } from "@/types/layout";
import { useTracking } from "@/hooks/useTracking";
import { useMediaQueries } from "@/hooks/useMediaQueries";
import { usePersistState } from "@/hooks/usePersistState";

import Styles from "./accordion.module.scss";

export const useAccordion = ({
	autoOpen = false,
	type,
	id,
	trackOpen,
	modalRef,
}: Omit<AccordionProps, "children">) => {
	const { value, setValue } = usePersistState({
		defaultValue: autoOpen,
		key: `${id}_accordion_state` || "",
	});
	const [open, setOpen] = useState(autoOpen);
	const accordionContent = useRef<HTMLDivElement>(null);
	const { trackAccordionOpen } = useTracking();
	const { isMobile } = useMediaQueries();

	const toggleAccordion: MouseEventHandler<HTMLDivElement> = useCallback(
		(event) => {
			if (
				(event.target as Element).classList.contains(Styles.accordionHeading) ||
				// @ts-ignore
				(event.target as Element)?.parentNode?.classList.contains(
					Styles.accordionHeading
				) ||
				//@ts-ignore
				(event.target as Element).parentNode.parentNode.parentNode.classList // @ts-ignore // TODO - this is bad
					.contains("accordion-alternate") ||
				//@ts-ignore
				(event.target as Element).parentNode.parentNode.classList // @ts-ignore // TODO - this is bad
					.contains(Styles.accordionHeading)
			) {
				if (type === "icon" && isMobile) {
					return;
				}

				if (trackOpen && value === false && id) {
					trackAccordionOpen(id);
				}

				id ? setValue(!value) : setOpen(!open);
				slideToggle(accordionContent.current);

				if (modalRef) {
					const y =
						(accordionContent?.current?.getBoundingClientRect?.()?.top || 0) +
						(accordionContent?.current?.offsetHeight || 0) +
						250;
					setTimeout(() => {
						modalRef?.current?.scrollTo({ top: y, behavior: "smooth" });
					}, 500);
				}
			}
		},
		[
			id,
			isMobile,
			modalRef,
			open,
			setValue,
			trackAccordionOpen,
			trackOpen,
			type,
			value,
		]
	);

	return {
		toggleAccordion,
		accordionContent,
		open: id ? value : open,
	};
};
